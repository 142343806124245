import React, { useEffect, useState,useRef } from "react";

import ShovelImage from "./assets/img/shovel.png";
import "./style.css";
import { Finger } from "components/Finger/Finger";
import { randomNumberInRange } from "helpers/randomNumberInRange";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Rank } from "components/Rank/Rank";
import { useGlobalStore } from "stores/global";

export const ShovelAnimation = ({show,link}) => {
  const [coins, setCoins] = useState([]);



  const navigate = useNavigate();

  const rank = useGlobalStore(state => state.user_info.rank );

  const handleNavigate = ()=>{

   
    navigate(link)
  }

  useEffect(() => {
    if(!show) return
    
    const width = window.innerWidth;
    const height = window.innerHeight ;

    const interval = setInterval(() => {
        const coinX = randomNumberInRange(width / 4.4, width / 1.5); 
        const coinY = randomNumberInRange(height - 130, height - 115);


        handleCoins(coinX,coinY)
    },150)

    return () => clearInterval(interval);
  },[show])

  const handleCoins = (clientX,clientY) => {

    // 42 - coin width
    const x =
    clientX + 42 > window.innerWidth
      ? window.innerWidth - 42
      : clientX;
    const y = clientY;

    const newCoins = [{ id: Date.now(), x, y }]
    setCoins((prevCoins) => [...prevCoins, ...newCoins]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCoins(
        (prevCoins) => prevCoins.filter((coin) => Date.now() - coin.id < 7000) // 7-second animation
      );
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home__shovel">
      <Rank place = {rank}/>
      <Finger show = {!show}/>
    <div className={`shovel `} onClick = {handleNavigate}>
      {coins.map((coin) => (
        <div
          key={coin.id}
          className="coin"
          style={{ left: coin.x, top: coin.y }}
        />
      ))}
      <img className="shovel__img" src={ShovelImage} alt="" />
    </div>

    </div>
  );
};
