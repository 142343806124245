import animation from "./assets/animation.json";
import Lottie from "lottie-react";
import "./style.css";
import { useState } from "react";

export const Finger = ({show})=>{

    if(!show) return null

    return <Lottie className = "finger" animationData={animation} loop={true} />
}