import pagesData from "config/pagesData.json";
import Diamond from "components/StoryLine/assets/diamond.svg";
import Cup from "components/StoryLine/assets/cup.svg";
import Skull from "components/StoryLine/assets/skull.svg";
import Box from "components/StoryLine/assets/box.svg";
import Gold from "components/StoryLine/assets/gold.svg";
import Crown from "components/StoryLine/assets/Crown.svg";
import Chest from "components/StoryLine/assets/Chest-earth.svg"
import Cup2 from "components/StoryLine/assets/Cup2.svg";

export const getRoadmapConfig = () => {

    console.log(pagesData)
    const images = [Diamond, Cup, Box, Gold, Cup2, Chest];
    const roadmapData = pagesData.roadmap_page.roadmap.map((roadmap, idx) => {
        return {
            ...roadmap,
            img: images[idx]
        }
    })

    return roadmapData
}