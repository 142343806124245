import animation from "./assets/animation.json";
import Lottie from "lottie-react";
import { useRef } from "react";

export const Compass = ({animate})=>{

    const lottieRef = useRef();

    if(lottieRef.current) {
        if(animate) {
            lottieRef.current.play()
        } else {
            lottieRef.current.stop()
        }
    }

    return <Lottie style = {{transform:"scale(2.2)"}} lottieRef={lottieRef} autoplay = {false} animationData={animation} loop={true} />
}