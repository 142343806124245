import "./style.css"
import Loader from "components/icons/Loader"
import anonim from "./assets/anonimous-miner.png"

export const KlondikeButton = ({style,img,text,clickHandler, loading = false,imageBefore = false, disabled = false, className}) => {

    if(loading) {
        return <div className = {`klondike-button ${style ? style: ""} ${className ? className : ""} `} onClick = {clickHandler}>
                <Loader fill="#582B02" width={24} height={24} />
        </div>
    }

    return <div className = {`klondike-button ${style ? style: ""} ${className ? className : ""} ${!disabled ? "enabled" : ""}`} onClick = {clickHandler}>
            { (imageBefore && img) && <img style = {{borderRadius: "24px"}} src = {img} onError = {(e) => (e.target.src = anonim)} alt = ""/>}
            {text && <span>{text}</span>}
            {(!imageBefore && img) && <img src = {img} onError = {(e) => (e.target.src = anonim)} alt = ""/>}
            
    </div>
}