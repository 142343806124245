import './style.css'

export const LoadingBar = ({width,text})=>{
    return (
        <div className="progress-block">
        <div className = "progress-block__text">
            <span>
                {text}
            </span>
            <span>
            {width + "%"}
            </span>
        </div>
          <div className="progress-bar">
            <div className="progress-bar__line">
              <span
                className="progress-bar__left"
                style={{ width: `${(width)}%` }}
              ></span>


              {/* <span
                className="progress-bar__circle"
                style={{
                  left: `${
                    (width) > 3 && (width) < 97
                      ? `calc(${(width)}% - 14px)`
                      : (width) > 97
                      ? "auto"
                      : "0"
                  }`,
    
                  right: `${(width) > 97 ? "0" : "auto"}`,
                }}
              ></span> */}
              <span className="progress-bar__right"></span>
            </div>
          </div>
        </div>
      );
}