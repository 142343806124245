import "./style.css"
import KlondikeCoin from "./assets/klondike-coin-logo.png"
import Points from "./assets/points.png"
import { unixToDateString } from "helpers/unixToDateString"
import shortedNumber from "helpers/shortedNumber"


export const HistoryTable = ({data,pageData,language}) => {


    return <table className = "history-table">
         <thead>
                <tr>
                    <th>{pageData.date[language]}</th>
                    <th>{pageData.game[language]}</th>
                    <th>{pageData.winner[language]}</th>
                    <th>{pageData.prize[language]}</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item,index) => (<tr key = {index}>
                    <td>{unixToDateString(item.date).split(" ")[0]}</td>
                    <td>{item.gameId}</td>
                    <td className = "history-table__winner">
                        <span>{item.winner}</span>
                    </td>
                    <td className = "history-table__prize">
                        <img src = {item.type === 0 ? Points : KlondikeCoin} alt = "" />
                        <span>{shortedNumber(item.prize)}</span>
                    </td>
                </tr>))}
            </tbody>

    </table>

}