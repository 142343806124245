import React from "react";
import "./style.css";

import { getRoadmapConfig } from "helpers/getRoadmapConfig";
import Ready from "./assets/ready.svg";
import { useGlobalStore } from "stores/global";

export const StoryLine = () => {
  const data = getRoadmapConfig()

  const language = useGlobalStore(state => state.user_info.language);

  return (
    <ul className="storyline">
      {data.map((item, index) => {
        return (
          <li className={`storyline__item`} key={index}>
            <div className="storyline__img-block">
              <img src={item.img} alt="" className="storyline__img" />
            </div>
            <div className="storyline__content">
              <span className="storyline__title">
                {item.title[language]}{" "}
                {item.finished && (
                  <img className="storyline__ready-icon" src={Ready} alt="" />
                )}
              </span>
              <p className="storyline__text">{item.text[language]}</p>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
