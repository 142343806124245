import React, { useEffect, useState } from "react";

import "./style.css";

import { useGlobalStore } from "stores/global";
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig";

export const Notification = ({ message, username, points, onClose }) => {
  const [hideNotification, setHideNotification] = useState(false);

  const language = useGlobalStore(state => state.user_info.language);

  const notification = useGlobalStore(state => state.notification);

  const {setNotification} = useGlobalStore();

  const pointsMsg = getInfoWindowsConfig("points")

  useEffect(() => {
    const timer = setTimeout(() => {
      setHideNotification(true);
      setNotification({
        ...notification,
        show: false
      })
    }, 3000); // Auto close after 3 seconds

    const timer2 = setTimeout(() => {
      onClose();
    }, 4000); // Auto close after 4 seconds

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, [onClose]);

  return (
    <div
      className={`notification ${hideNotification ? "notification-hide" : ""}`}
    >
      <b>{username}</b>
      {message}
      <b>{points ? points + " " + pointsMsg[language] : ""}</b>

      <span className="notification__line"></span>
    </div>
  );
};
