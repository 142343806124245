import AppWidthWithContainer from "components/AppWidthContainer/AppWidthWithContainer"
import "./style.css"
import Title from "components/Title/Title"
import { useGlobalStore } from "stores/global"
import { HeaderCells } from "components/HeaderCells/HeaderCells"
import Cup from "./assets/Cup.png"
import TON from "./assets/TON.png"
import { KlondikeButton } from "components/KlondikeButton/KlondikeButton"
import { useEffect, useState } from "react"
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig"
import { Leaderboard } from "components/Leaderboard/Leaderboard"
import ModalWindow from "components/ModalWindow/ModalWindow"
import Tick from "./assets/tick.png"
import WebApp from "@twa-dev/sdk"
import { getPageData } from "helpers/getPageData"



export const Competition = () => {

    const [isOpen, setIsOpen] = useState(false);

    const tg_info = useGlobalStore(state => state.tg_info );
    const avatar = useGlobalStore(state => state.user_info.avatar );
    const token_balance = useGlobalStore(state => state.user_info.token_balance );
    const language = useGlobalStore(state => state.user_info.language);

    const pageData = getPageData('competition')


    const api = useGlobalStore(state => state.api );

    const leaderboard = useGlobalStore(state => state.leaderboard );

    const { setNotification,  setLeaderboard} = useGlobalStore();
    

    const defaultMsg = getInfoWindowsConfig('default');

    const openHandler = () => {
        setIsOpen(true);
    }

    const closeHandler = () => {
        setIsOpen(false);
    }

    const invitePeople = () => {
        const messageRu = '%0AВ событии "Золотая лихорадка" Klondike разыгрывает 500 TON! Присоединяйся к моей команде и зарабатывай вместе со мной!'
        const messageEn = '%0AThe "Gold Rush" event of Klondike rewards 500 TON! Join my team and make money together with me!'

        const link = `https://t.me/share/url?text=${language === "ru" ? messageRu : messageEn}&url=${process.env.REACT_APP_BOT_URL}?start=${tg_info.chat_id}&space=true`
        WebApp.openTelegramLink(link)
    }

    useEffect(() => {
        const fn = async () => {
            // const fromTimeUnix = 1707588964
            const fromTimeUnix = Math.round(Date.now() / 1000);
            const board = await api.getUserLeaderboard(fromTimeUnix - 60*60*24*30);

            if(!board) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: defaultMsg.error[language]
                }

                setNotification(notification)

                return
            }

            setLeaderboard(board)

            
        }

        if(!leaderboard.top_referrers) {
            fn();
        }

    },[])


    return <AppWidthWithContainer>
            <HeaderCells image = {avatar} text = {tg_info.tg_name} tokenBalance = {token_balance}/>
            <div className = "competition">
                <Title size = "medium" text = {pageData.title[language]}/>
                <div className = "competition__image-block">
                    <img src = {Cup} alt = ""/>
                    <div className = "competition__image-block-prize">
                        <img src = {TON} alt = ""/>
                        <span>500 TON</span>
                    </div>
                </div>

                <div className = "competition__btns">
                    <KlondikeButton text = {pageData.rules[language]} style = "wooden" clickHandler={openHandler}/>
                    <KlondikeButton text = {pageData.call[language]} style = "gold" clickHandler={invitePeople}/>
                </div>
                <Title size = "medium" text = {pageData.leaderboard[language]}/>
                <Leaderboard data = {leaderboard.top_referrers} me = {leaderboard.user_data}/>
                <span></span>
                <ModalWindow isOpen = {isOpen} onClose = {closeHandler}>
                    <div className = "modal-content-inner">
                        <span className = "modal-content-inner__title">
                            Условия участия:
                        </span>
                        <div className = "modal-content-inner__list">
                            <img src = {Tick} alt = ""/>
                            <span>Все участники должны быть зарегистрированы на сайте</span>
                        </div>
                        <div className = "modal-content-inner__list">
                            <img src = {Tick} alt = ""/>
                            <span>Все участники должны быть зарегистрированы на сайте</span>
                        </div>
                        <div className = "modal-content-inner__list">
                            <img src = {Tick} alt = ""/>
                            <span>Все участники должны быть зарегистрированы на сайте</span>
                        </div>
                    </div>
                </ModalWindow>
            </div>
           
    </AppWidthWithContainer>
}