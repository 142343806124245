import React from "react";
import AppWidthWithContainer from "../../components/AppWidthContainer/AppWidthWithContainer";
import Title from "../../components/Title/Title";

import "./style.css";
import { HistoryTabs } from "../../components/HistoryTabs/HistoryTabs";
import { useGlobalStore } from "stores/global";
import { getPageData } from "helpers/getPageData";
import { useState,useEffect } from "react";

const Tasks = () => {

  const [loading, setLoading] = useState(true);

  const tasks = useGlobalStore(state => state.tasks );

  const api = useGlobalStore(state => state.api);

  const language = useGlobalStore(state => state.user_info.language);

  const google = useGlobalStore(state => state.google);


  const pageData = getPageData('tasks_page');

  useEffect(() => {
    google.trackPageView('/tasks', 'Tasks');  
  },[])

  

  return (
    <AppWidthWithContainer>
      <div className="tasks">
        <Title text={pageData.title[language]} />
        <div className="tasks__content">
           <HistoryTabs tasks = {tasks}/>
        </div>
      </div>
    </AppWidthWithContainer>
  );
};

export default Tasks;
