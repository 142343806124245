import App from 'app/App'
import './style.css'
import AppWidthWithContainer from 'components/AppWidthContainer/AppWidthWithContainer'
import { TonButton } from 'components/TonButton/TonButton'
import Arrow from './assets/arrow-3.png'
import TonLogo from "./assets/toncoin-ton-logo.svg"
import KlondikeCoin from "./assets/klondike-coin-logo-2.png"
import Title from 'components/Title/Title'
import { useState,useEffect } from 'react'
import { useTonAddress,useTonConnectUI } from '@tonconnect/ui-react'
import { ChainStack } from 'entities/chainstack'
import { useGlobalStore } from 'stores/global'
import { getInfoWindowsConfig } from 'helpers/getInfoWindowsConfig'
import { Popup } from 'components/Popup/Popup'
import { HeaderCells } from 'components/HeaderCells/HeaderCells'
import { TransactionItem } from 'components/TransactionItem/TransactionItem'
import { getPageData } from 'helpers/getPageData'
import Loader from 'components/icons/Loader'
import { NoData } from 'components/NoData/NoData'
import { TON } from 'entities/TON'

export const Swap = () => {

    const token_balance = useGlobalStore(state => state.user_info.token_balance );

    const pageData = getPageData('swap_page');
    const language = useGlobalStore(state => state.user_info.language);

    const [loading, setLoading] = useState(false);

    const [sellInput, setSellInput] = useState()
    const [buyInput, setBuyInput] = useState()

    const [btnText, setBtnText] = useState(pageData.connect[language])

    const TonPriceInTokens = 1000

    const fee = 0.03


    const [route, setRoute] = useState({
        from: {
            name: 'TON',
            logo: TonLogo,
            balance: 0
        },
        to: {
            name: 'KLD',
            logo: KlondikeCoin,
            balance: token_balance
        }
    })

    const address = useTonAddress()
    const [tonConnectUI] = useTonConnectUI()

    const {setPopup,setNotification,setTransactionsHistory,addTransaction,removeUserTokenBalance} = useGlobalStore()

    const popup = useGlobalStore(state => state.popup );
    const tg_info = useGlobalStore(state => state.tg_info );
    const avatar = useGlobalStore(state => state.user_info.avatar );

    const tonMsg = getInfoWindowsConfig("ton_wallet")
    const defaultMsg = getInfoWindowsConfig("default")
    const notEnough = getInfoWindowsConfig("not_enough_balance")
    const swapSuccess = getInfoWindowsConfig("swap_success")
    const minAmountError = getInfoWindowsConfig("min_amount_error")
    const minAmountKldError = getInfoWindowsConfig("min_amount_token_error")

    const transactions_history = useGlobalStore(state => state.transactions_history );

    const api = useGlobalStore(state => state.api );


    
    const changeRoute = () => {
        
        setRoute(
            {
                from: route.to,
                to: route.from
            }
        )

        const toTon = route.to.name === "TON"

        const tempSell = !toTon ? sellInput * (1-fee) : sellInput
        const tempBuy = toTon ? sellInput / TonPriceInTokens : buyInput

        setSellInput(tempBuy)
        setBuyInput(tempSell)

    }

    const sellInputChange = (e) => {
        const value = e.target.value

        if(route.from.name === "TON") {
            setBuyInput(value * TonPriceInTokens)
        } else {
            const input = value * (1 - fee) / TonPriceInTokens
            setBuyInput(input.toFixed(5))
        }

        setSellInput(value)

    }

    const buyInputChange = (e) => {
        const value = e.target.value

        if(route.to.name === "TON") {
            setSellInput(value *  TonPriceInTokens * (1+fee))
        } else {
            setSellInput(value / TonPriceInTokens)
        }

        setBuyInput(value)
    }

    const swapHandler = async () => {
        if(!address) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: tonMsg.not_connected_swap[language]
            }
            
            setNotification(notification)
            setPopup(true)
            return
        }

        const transactionType = route.from.name === "TON" ? "buy" : "sell"

        if(transactionType === "buy") {
            if(sellInput < 0.01) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: minAmountError[language]
                }

                setNotification(notification)
                return
            }
        }

        if(transactionType === "sell") {
            
            if(sellInput < 100) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: minAmountKldError[language]
                }

                setNotification(notification)
                return
            }   
        }

        if(route.from.balance < sellInput) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: notEnough[language]
            }

            setNotification(notification)
            return
        }

        //send request to server

        if(transactionType === "buy") {


            setLoading(true)
            const order = await api.createDepositOrder(sellInput)

            console.log(order)
            const orderHash = order.uuid
            

            if(!orderHash) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: defaultMsg.error[language]
                }

                setNotification(notification)
                setLoading(false)
                return

            }

            const ton = new TON(tonConnectUI, address)


            const transaction = await ton.sendTransactionWithPayload(sellInput, order.payload)
            setLoading(false)

            if(!transaction) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: tonMsg.transaction_error[language]
                }

                setNotification(notification)
                return
            }

            // add transaction to history
            addTransactionToList(orderHash, sellInput, 0)
        }

        if(transactionType === "sell") {
            setLoading(true)
            const orderHash = await api.exchangeTokensForTon(sellInput)
            setLoading(false)

            if(!orderHash) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: defaultMsg.error[language]
                }

                setNotification(notification)
                return
            }

            removeUserTokenBalance(sellInput)

            const notification = {
                show: true,
                points: '',
                username: '',
                message: swapSuccess[language]
            }

            setNotification(notification)

            //add transaction to history
            addTransactionToList(orderHash, buyInput, 1,1)


        }


        
    }

    const addTransactionToList = (orderHash, amount, type,status = 0) => {

        //type 0,1
        //0 - buy
        //1 - sell
        
        const transaction = {
            amount,
            created_at: Math.floor(Date.now() / 1000),
            hash: orderHash,
            status: 0,
            type
        }

        addTransaction(transaction)
    }

    useEffect(()=>{
        const fn = async () => {
            const chainstack = new ChainStack()
            const balance = await chainstack.getWalletTonBalance(address)


            setBtnText(pageData.swap[language])

            if(!balance) {
                return
            }

            if(route.from.name === "TON") {
                setRoute({
                    ...route,
                    from: {
                        ...route.from,
                        balance: balance
                    }
                })
            } else {
                setRoute({
                    ...route,
                    to: {
                        ...route.to,
                        balance: balance
                    }
                })
            }

            
        }

        if(address) {
            fn()
        }

    },[address])

    useEffect(()=>{
        const fn = async () => {
            
            const history = await api.getTransactionsHistory()
            

            if(!history) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: 'Failed to fetch transactions history. Please try again later'
                }

                setNotification(notification)
                return
            }

            setTransactionsHistory(history)

            console.log(history)

        }

        if(!transactions_history) {
            fn()
        }
    },[])

    return <AppWidthWithContainer className = "swap"> 
    <Popup isOpen = {popup.isOpen} onClose = {() => useGlobalStore.setState({popup: false})} img = {avatar} text = {tg_info.tg_name}/>
    <HeaderCells image = {avatar} text = {tg_info.tg_name} tokenBalance = {token_balance}/>
        <div className = "swap-page">
        
        
            <div className = "swap-page__swap">
                <span className = "swap-page__swap-title">{route.from.name === "TON" ? pageData.swap_title.buy[language] : pageData.swap_title.sell[language]} $KLD</span>
                 <div className = "swap-page__swap-container">
                    <div className = "swap-page__swap-input sell">
                        <span className = "swap-page__swap-input__descr">{pageData.you_sell[language]}</span>
                        <div className = "swap-page__swap-input__block sell-block">
                            <img src = {route.from.logo} className = "coin-img"/>
                            <span>{route.from.name}</span>
                            <input placeholder = "0" type = "number" pattern="[0-9]*[.,]?[0-9]*" inputmode="decimal"  value = {sellInput} onChange = {sellInputChange}/>
                        </div>
                        <div className = "swap-page__swap-input__balance">
                            <span className = "swap-page__swap-input__descr">{pageData.balance[language]}</span>
                            <span className = "swap-page__swap-input__descr">{route.from.balance}</span>
                        </div>
                    </div>
                    <img src = {Arrow} onClick = {changeRoute}/>
                    <div className = "swap-page__swap-input buy">
                        <span className = "swap-page__swap-input__descr">{pageData.you_buy[language]}</span>
                        <div className = "swap-page__swap-input__block buy-block">
                            <img src = {route.to.logo} className = "coin-img"/>
                            <span>{route.to.name}</span>
                            <input placeholder = "0" type = "number" pattern="[0-9]*[.,]?[0-9]*"  inputmode="decimal"  value = {buyInput} onChange = {buyInputChange}/>
                        </div>
                        <div className = "swap-page__swap-input__balance">
                            <span className = "swap-page__swap-input__descr">{pageData.balance[language]}</span>
                            <span className = "swap-page__swap-input__descr">{route.to.balance}</span>
                        </div>
                    </div>
                 </div>
                <TonButton disabled = {loading} loading = {loading} text = {btnText} style = "klondike" clickHandler = {swapHandler}/>
            </div>

            <Title text = {pageData.history[language]} size = "small"/>

            <div className = "swap-page__history">

                {
                    !transactions_history ? 
                    <div className = "transaction__history-loader">
                    <Loader fill = "#582B02" width = {40} height = {40}/>
                    </div>
                    : transactions_history.length == 0 && 
                    <NoData color = "#4b2c20" title = {pageData.noHistory.title[language]} text = {pageData.noHistory.text[language]}/>
                   
                    
                }
                {
                     transactions_history && transactions_history.sort((a, b) => b.created_at - a.created_at).map((item, index) => {
                        return <TransactionItem type = {item.type} key = {index} amount = {item.amount} created_at = {item.created_at} hash = {item.hash} status = {item.status}/>;
                    })
                }
                 <span></span>
            </div>

        </div>
    </AppWidthWithContainer>

    
    
}