import "./style.css" 

import React, { useState,useEffect } from 'react';
import CloseIcon from "./assets/close.png"

const ModalWindow = ({ children, isOpen, onClose, className }) => {

    const [animate, setIsAnimate] = useState(false)

    const handleModalClose = () => {
        onClose()
    };

    const handleOverlayClick = () => {
        handleModalClose();
    };


  return (
    <div>
      
        
        <div className={`modal-content ${className ? className : ''} ${isOpen ? 'open' : ''}`}>
        <button className="close-button" onClick={handleModalClose}>
          <img src = {CloseIcon}/>
        </button>
        {children}
      </div>

        { isOpen && <div className="modal-overlay" onClick={handleOverlayClick}>
        </div>}
        
    </div>
  );
};

export default ModalWindow;