import { CloudStorage } from "entities/cloudStorage";
import { useEffect } from "react";
import { useNavigate, useLocation, redirect } from "react-router-dom";

const RedirectMiddleware = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        console.log(location)
        const searchParams = new URLSearchParams(location.search);
        const startParam = searchParams.get("tgWebAppStartParam")

        if(!startParam) {
            return
        }

        const command = startParam.split("=")[0]

        if(command !== "redirect") {
            return
        }

        const redirectPath = startParam.split("=")[1];

    

        if (redirectPath) {
            const path = redirectPath.split("_").concat("").join("/").slice(0, -1);
            
            navigate(location.pathname)
            navigate(`/${path}`); 
            
        }

      

        
    }, [navigate, location]);

    return children;
};

export default RedirectMiddleware;