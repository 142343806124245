import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./style.css";

export const GameRulesSlider = ({data,language}) => {
    return (
        <Swiper
          modules={[Navigation]}
          navigation
          spaceBetween={20}
          slidesPerView={1}
          loop={true}
        >
          {data.map((slide, idx) => {
            return (
              <SwiperSlide key={idx}>
                <span className="game-rules-slider__title">{slide.title[language]}</span>
                <p className="game-rules-slider__text">{slide.text[language]}</p>
                <img className="game-rules-slider__img" src={slide.img} alt="" />
    
              </SwiperSlide>
            );
          })}
        </Swiper>
      );
}