import "./style.css"
import Loader from "components/icons/Loader"
import GoldCup from "./assets/golden-cup.png"
import SilverCup from "./assets/silver-cup.png"
import BronzeCup from "./assets/bronze-cup.png"
import { KlondikeButton } from "components/KlondikeButton/KlondikeButton"
import { overflowText } from "helpers/overflowText"
import TON from "./assets/TON.png"
import { useGlobalStore } from "stores/global"
import { getPageData } from "helpers/getPageData"

export const Leaderboard = ({data,me}) => {
    
    const language = useGlobalStore(state => state.user_info.language);
    const pageData = getPageData("competition");
    
    if(!data) {
        return <div className = "loader">
            <Loader fill = "#582B02" height = {40} width = {40}/>
        </div>
    }

   
    

    const isMeInList = data.find(item => item.me === true) 

    if(!isMeInList) {
        data.push(me)
    }

        return <div className = "competition__leaderboard">
            <div className = "competition__leaderboard-row title-row">
                <span>{pageData.invitation_table.place[language]}</span>
                <span>{pageData.invitation_table.player[language]}</span>
                <span>{ pageData.invitation_table.invites[language]}</span>
                <span>{ pageData.invitation_table.reward[language]}</span>
            </div>
            {
                data.map((item,idx) => {
                    return <div className = "competition__leaderboard-row">
                            {
                                item.position === 1 &&
                                <>
                                <KlondikeButton disabled = {true} style = "sharp" img = {GoldCup} className = {`place ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "sharp" imageBefore = {true} img = {item.avatar} text = {overflowText(item.username,5)} className = {`user ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "sharp" text = {item.invite_count} className = {` invites ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "sharp" imageBefore = {true} img = {TON} text = "???" className = {` reward ${item.me ? "red-line": ""}`}/>
                                </>
                            } 
                            {
                                item.position === 2 &&
                                <>
                                <KlondikeButton disabled = {true} style = "silver" img = {SilverCup} className={`place ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "silver" imageBefore = {true} img = {item.avatar} text = {overflowText(item.username,5)} className={`user ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "silver" text = {item.invite_count}  className={` invites ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "silver" imageBefore = {true} img = {TON} text = "???" className={` reward ${item.me ? "red-line": ""}`}/>
                                </>
                            }
                            {
                                item.position === 3 &&
                                <>
                                <KlondikeButton disabled = {true} style = "bronze" img = {BronzeCup} className={`place ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "bronze" imageBefore = {true} img = {item.avatar} text = {overflowText(item.username,5)} className={`user ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "bronze" text = {item.invite_count} className={` invites ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "bronze" imageBefore = {true} img = {TON} text = "???" className={` reward ${item.me ? "red-line": ""}`}/>
                                </>
                            }
                            {
                                item.position > 3 && 
                                <>
                                <KlondikeButton disabled = {true} style = "wooden" text = {item.position} className = {`place ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "wooden" imageBefore = {true} img = {item.avatar} text = {overflowText(item.username,5)} className = {`user ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "wooden" text = {item.invite_count} className = {` invites ${item.me ? "red-line": ""}`}/>
                                <KlondikeButton disabled = {true} style = "wooden" imageBefore = {true} img = {TON} text = "???" className = {` reward ${item.me ? "red-line": ""}`}/>
                                </>
                            }
                    </div>
                })
            }
    </div>
}
