import axios from "axios";
import Cookies from "js-cookie";
import { Utils } from "./utils";

export class API {
    static instance;
    api;
    id;
    db_id;

    constructor(id) {

        if(API.instance) {
            return API.instance
        }

        this.id = id
        this.db_id = null

        this.api = axios.create({
            baseURL: process.env.REACT_APP_BASE_API_URL,
        })

        // ngrok tunnel
        this.api.defaults.headers.common['ngrok-skip-browser-warning'] = 'true'

        API.instance = this
    }

    async authenticate() {

        const token = Cookies.get('access_token')

        if(token) {

            if(!this.api.defaults.headers.common['Authorization']) {
                this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`
            }
            return 
        }

        try {

            const response = await axios.post(
              `${process.env.REACT_APP_BASE_API_URL}/auth/token/`,
              {
                telegram_id : `${this.id}`,
              },
              {
                headers: {
                  "ngrok-skip-browser-warning": "true",
                }
              }
            );

            const now = Date.now();
            const expirationTime = new Date(now + process.env.REACT_APP_TOKEN_EXPIRATION_TIME * 1000)

            Cookies.set('access_token', response.data.access_token, { path: '/', expires: expirationTime });
            
            this.api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`


        } catch(err) {
            console.log(err)
            return null
        }


    }

    async getUserInfo () {

       try {

            await this.authenticate()

            const response = await this.api.get('/users/me/')

            if(!this.db_id) {
                this.db_id = response.data.id
            }

            return response.data
       } catch(err) {
            console.log(err)
            return null
       }
    }

    async getUserTasks() {
        await this.authenticate()

        try {
            const response = await this.api.get(`/user-tasks/`)

            return response.data
        } catch(err) {
            console.log('error')
            return null
        }
    }

    async claimRewards(task_id) {

        await this.authenticate()

        try {
            const response = await this.api.post(`/tasks/reward/`, {
                task_id
            })

            return response.data
        } catch (err) {

            console.log(err)
            return null
        }
    }

    async checkSubscription(task_id) {

        await this.authenticate()

        try {
            const response = await this.api.post(`/tasks/check/`, {
                task_id
            })
            
            return response.data
            
        }  catch (err) {

            console.log(err)
            return null
        }
    }

    async authTonWallet(telegram_id, wallet_address) {

        await this.authenticate()

        try {
             const response = await this.api.post(`/auth/telegram_wallet/`, {
                 telegram_id: telegram_id.toString(),
                 wallet_address
             })

             return response.data

        } catch (err) {

            console.log(err)
            return null
        }

    }

    async authTwitter() {

        await this.authenticate()

        try {
            const response = await this.api.post(`/auth/twitter/`,{
                telegram_id: this.id.toString()
            })

            return response.data
        } catch(err) {

            console.log(err)
            return null
        }
    }

    async startTask(){

        await this.authenticate()

        try{

            const response = await this.api.post(`/tasks/start/`)

            return response.data
        }catch(err){

            console.log(err)
            return null
        }
    }

    async getFarmInfo() {

        try {

            await this.authenticate()

            const response = await this.api.get(`/farming/get-farm/`)

            const farm = response.data

            return {
                status: farm.status,
                time_left: farm.time_left,
                coins_farmed: farm.farmed_so_far,
                total_coins:farm.total_farmed,
                loaded_at: Math.floor(Date.now() / 1000),
            }

        } catch(err) {
            console.log(err)
            return null
        }
    }

    async startFarming() {
        try {
            await this.authenticate()

            const response = await this.api.post(`/farming/start/`)

            const farm = response.data

            return {
                status: farm.status,
                time_left: farm.time_left,
                coins_farmed: farm.farmed_so_far,
                total_coins:farm.total_farmed,
                loaded_at: Math.floor(Date.now() / 1000),
            }
            
        } catch(err) {
            console.log(err)
            return null
        }

        
    }

    async claimFarming() {

        try {
            await this.authenticate()

            const response = await this.api.post(`/farming/claim/`)

            return response.data
        } catch(err) {
            console.log(err)
            return null
        }
    }

    async claimReferralFarming() {

        try {
            await this.authenticate()

            const response = await this.api.post(`/farming/claim-referral/`)

            return response.data
        } catch(err) {
            console.log(err)
            return null
        }
    }

    async startGame(game_id) {
        try{
            await this.authenticate()

            const response = await this.api.post('/games/start/', {
                game_id
            })

            return response.data.game_hash
        } catch(err) {
            console.log(err)
            return null
        }
    }

    async getAttemptsLeft(game_id) {
        try {

            await this.authenticate()

            const response = await this.api.get(`/games/${game_id}/attempts_left/`, {
                
            })

            return {
                attempts: response.data.attempts_left
            }
        } catch(err) {
            console.log(err)
            return null
        }
    }

    async claimGameReward(game_id, encrypted_data) {
        try {

            await this.authenticate()

            const response = await this.api.post(`/games/claim/`, {
                game_id,
                encrypted_data
            })

            return {
                amount: response.data.amount
            }

        } catch (err) {
            console.log(err)
            return null
        }
    }

    async claimGameRewardTon(game_id, encrypted_data) {
        try {

            await this.authenticate()

            const response = await this.api.post(`/games/claim/transactions/`, {
                game_id,
                encrypted_data
            })

            return {
                amount: response.data.amount
            }

        } catch (err) {
            console.log(err)
            return null
        }
    }

    async getUserGift() {
        try {

            await this.authenticate()

            const response = await this.api.get(`/gifts/get_unclaimed_by_user/`)

            const giftHash = response.data[0].external_gift_hash

            const utils = new Utils(this.id)

            const decrypted = utils.decryptTonGift(giftHash,Cookies.get('access_token'))

            const amount = Number(decrypted.split(":")[1])

            return {
                hash: giftHash,
                amount,
                show:true
            }



        } catch (err) {
            console.log(err)
            return null
        }
    }

    async claimUserGift(giftHash) {
        try {
            await this.authenticate()

            const response = await this.api.post(`/gifts/claim/`, {
                external_gift_hash: giftHash
            })

            return response.data
        } catch(err) {
            console.log(err)
            return null
        }
    }

    async getGiftsHistory() {

        try {
            await this.authenticate()

            const response = await this.api.get(`/transactions/get_by_user/`)

            return response.data

            
        } catch(err) {
            console.log(err)
            return null
            
        }
    }

    async getTransactionsHistory() {
        try {
            await this.authenticate()

            const response = await this.api.get(`/exchange/exchanges_by_user/`)

          

            const txs = response.data.map(tx => ({
                amount: tx.amount_ton,
                created_at: Math.floor(new Date(tx.created_at).getTime() / 1000),
                hash: tx.uuid,
                status: tx.status,
                type: tx.type
            }))

            return txs
        } catch(err) {
            console.log(err)
            return null
        }
    }

    async createDepositOrder(tonAmount) {
        try {
            await this.authenticate()

            const response = await this.api.post(`/exchange/deposit/`, {
                ton_amount: tonAmount
            })

            return response.data.result
        } catch(err){
            console.log(err)
            return null
        }
    }

    async exchangeTokensForTon(tokenAmount) {
        try {

            await this.authenticate()

            const response = await this.api.post(`/exchange/withdraw/`, {
                token_amount: tokenAmount
            })

            return response.data.result.uuid
            

        } catch(err) {
            console.log(err)
            return null
        }

    }

    async getLastBarKingGames() {
        try {

            await this.authenticate();

            const response = await this.api.get(`/games/game/winners-history/`)


            return response.data.map(game => ({
                date: Math.floor(new Date(game.timestamp).getTime() / 1000),
                gameId: game.room_id,
                winner: game.winner.username,
                prize: game.total_pool,
                type: game.room_type
            }))
            
        } catch(err) {
            console.log(err)
            return null
        }
    }

    async getUserFriends() {
        try {

            await this.authenticate()

            const response = await this.api.get(`/users/me/get_friends_info/`)

            return response.data

        } catch (err) {
            console.log(err)
            return null
        }
    }

    async submitBounty(url, description) {
        try {

            await this.authenticate()

            const response = await this.api.post(`/bounty/`, {
                url,
                description
            })


            return {
                created_at: Math.floor(Date.now() / 1000),
                ...response.data
            }
        } catch (err) {
            console.log(err)
            return null
        }
    }

    async getUserBounties() {
        try {

            await this.authenticate()

            const response = await this.api.get(`/bounty/my-applications/`)

            return response.data
            

        } catch(err) {
            console.log(err)
            return null
        }
    }

    async getUserLeaderboard(from_timestamp) {
        try {

            await this.authenticate()

            const response = await this.api.get(`/users/top-referrers/`,{
                params: {
                    from_timestamp
                }
            })

            return response.data

            const resp = [
                {
                    username: 'David',
                    logo: '',
                    invited: 200,
                },
                {
                    username: 'David',
                    logo: '',
                    invited: 100,
                },
                {
                    username: 'David',
                    logo: '',
                    invited: 200,
                },
                {
                    username: 'David',
                    logo: '',
                    invited: 100,
                },
                {
                    username: 'David',
                    logo: '',
                    invited: 200,
                },
                {
                    username: 'David',
                    logo: '',
                    invited: 100,
                },
                {
                    username: 'David',
                    logo: '',
                    invited: 200,
                },
                {
                    username: 'David',
                    logo: '',
                    invited: 50,
                },
                {
                    username: 'David',
                    logo: '',
                    invited: 200,
                },
                {
                    username: 'David',
                    logo: '',
                    invited: 100,
                }
            ]

            return resp


        } catch (err) {
            console.log(err)
            return null
        }
    }


}