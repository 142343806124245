import "./css/main.css";
import "./css/ios_fullscreen.css";
import "./css/reset.css"

import { useRef, useEffect, useState } from "react";
import { useGlobalStore } from "stores/global";
import { Popup } from "components/Popup/Popup";
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig";
import { TON } from "entities/TON";
import {useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import { useGameStore } from "stores/game";
import { Utils } from "entities/utils";
import { CloudStorage } from "entities/cloudStorage";




export const WhackEmAllGame = () => {

    const [isScriptLoaded, setIsScriptLoaded] = useState(false)
    const [walletConnected, setWalletConnected] = useState(false)
    const [rewardToClaim, setRewardToClaim] = useState(0)

    const game = useRef();

    const tg_info = useGlobalStore(state => state.tg_info );
    const avatar = useGlobalStore(state => state.user_info.avatar );
    const language = useGlobalStore(state => state.user_info.language );

    const api = useGlobalStore(state => state.api );
  
    const popup = useGlobalStore(state => state.popup);
    const {setPopup, setNotification} = useGlobalStore()

    const banditsGameHash = useGameStore(state => state.banditsGameHash);

    const tonWalletData = getInfoWindowsConfig("ton_wallet")
    const defaultMsg = getInfoWindowsConfig("default")
    const claimMsg = getInfoWindowsConfig("claim")

    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();

    const utils = new Utils(tg_info.chat_id)

    const cloudStorage = new CloudStorage()

    useEffect(()=>{
        const script = document.createElement('script');


        script.src = "/game/main.js";
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => {
            setIsScriptLoaded(true);

          };

        return () => {
            document.body.removeChild(script);
        }
    },[])
   
    useEffect(()=>{

        if(game.current) {
            return
        }

        if(!window.CMain) {
            return
        }
            
        game.current = new window.CMain({
                points:[10,20,30,40,-100], //POINTS ASSIGNED FOR EACH CHARACTER
                start_spawn_time:1000,     //STARTING TIME INTERVAL FOR SPAWNING
                time_offset:1000,          //TIME AMOUNT IN MILLISECONDS FOR INCREASE GAME DIFFICULTY
                offset_spawn_time:10,      //THIS AMOUNT IS DECREASED FROM SPAWN TIME WHEN SCORE OFFSET IS REACHED
                super_hammer_time:1500,    //IF 3 OR MORE MOLES ARE HIT DURING THIS TIME INTERVAL (MILLISECONDS TIME), SUPER HAMMER MULTIPLIER IS ASSIGNED
                super_hammer_mult:2,       //EACH MOLE SCORE IS MULTIPLIED FOR THIS AMOUNT WHEN GAME IS IN SUPER HAMMER MODE
                time_super_hammer: 10000,  //TIME DURATION IN MILLISECONDS OF SUPERHAMMER MULTIPLIER
                level_time:60000,          //TIME (IN MILLISECONDS) FOR LEVEL
                audio_enable_on_startup:false, //ENABLE/DISABLE AUDIO WHEN GAME STARTS 
                fullscreen:false            //SET THIS TO FALSE IF YOU DON'T WANT TO SHOW FULLSCREEN BUTTON
        });


        window.$(game.current).on("start_session", handleStartSession)
       
        window.$(game.current).on("claim_button_click", handleClaim)
        window.$(game.current).on("claimX3_button_click", handleClaimX3)

        window.$(game.current).on("save_score", saveScoreHandler)

        window.sizeHandler()

        const fn = async () => {

            const bestScore = await cloudStorage.getItem(`${tg_info.chat_id}_bandits_invasion`)

            if(!bestScore || bestScore == 0) {
                return
            }

            window.s_iBestScore = bestScore
        }

        fn()


    },[isScriptLoaded])

    const saveScoreHandler = async (evt,score) => {
        const best_score = await cloudStorage.getItem(`${tg_info.chat_id}_bandits_invasion`)

        if(best_score && best_score > score) {
            return
        }

        await cloudStorage.setItem(`${tg_info.chat_id}_bandits_invasion`, score, 31536000000)
    }


    useEffect(()=>{
        //send transaction
        if(!walletConnected) {
            return
        }

        if(!banditsGameHash) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: defaultMsg.error[language]
              
            }

            setNotification(notification)
            return
        }
        
        const fn = async () => {
            const tonWrapper = new TON(tonConnectUI, userFriendlyAddress);
            const transaction = await tonWrapper.sendTransaction(0.004)


            if(!transaction) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: tonWalletData.transaction_error[language]
                }

                setWalletConnected(false)

                setNotification(notification)
                return
            }

            const gameHashResult = utils.encryptTransactionClaim(banditsGameHash, rewardToClaim, userFriendlyAddress)

            const claim = await api.claimGameRewardTon(1, gameHashResult)

            if(!claim) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: defaultMsg.error[language]
                  
                }
    
                setNotification(notification)
                setWalletConnected(false)
                return
            }
    
            const notification = {
                show: true,
                points: claim.amount,
                username: '',
                message: claimMsg.success[language]
              
            }
    
            setNotification(notification)
    
            setTimeout(() => {
                window.location.reload()
            }, 1500);

        }

        fn()
    },[walletConnected])


    const handleStartSession = (evt) => {
        
    }

    const handleClaim = async (evt, score) => {
        if(!banditsGameHash) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: defaultMsg.error[language]
              
            }

            setNotification(notification)
            return
        }

        const gameHashResult = utils.encryptSimpleClaim(score, banditsGameHash)

        const claim = await api.claimGameReward(1, gameHashResult)

        if(!claim) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: defaultMsg.error[language]
              
            }

            setNotification(notification)
            return
        }

        const notification = {
            show: true,
            points: claim.amount,
            username: '',
            message: claimMsg.success[language]
          
        }

        setNotification(notification)

        setTimeout(() => {
            window.location.reload()
        }, 1500);
        
    }

    const handleClaimX3 = (evt, score) => {

        if(!banditsGameHash) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: defaultMsg.error[language]
              
            }

            setNotification(notification)
            return
        }


        if(!userFriendlyAddress) {
            //open window with connect button
            const notification = {
                show: true,
                points: '',
                username: '',
                message: tonWalletData.not_connected[language]
              
            }

            setTimeout(() => {
                setNotification(notification)
            }, 1000)

            setPopup(true)
            return
        }

        setRewardToClaim(score)

        setWalletConnected(true)

    }

   
    return(
        <>
        <Popup onWalletConnect = {()=>setWalletConnected(true)} isOpen = {popup.isOpen} onClose = {() => useGlobalStore.setState({popup: false})} img = {avatar} text = {tg_info.tg_name}/>
        <div style={{position: "fixed", backgroundColor: "transparent", top: "0px", left: "0px", width: "100%", height: "100%"}}></div>
        <canvas id="canvas" className='ani_hack' width="1920" height="1920"> </canvas>
        <div id="block_game" style={{position: "fixed", backgroundColor: "transparent", top: "0px", left: "0px", width: "100%", height: "100%", display:"none"}}></div>
        </>
    )
}
