const shortedNumber = (number,toFixed = 2) => {
    if (number >= 1e6) {
        return (number / 1e6).toFixed(toFixed).replace(/\.0$/, '') + 'M';
    } else if (number >= 1e3) {
        return (number / 1e3).toFixed(toFixed).replace(/\.0$/, '') + 'k';
    } else {
        return number.toFixed(toFixed);
    }
}

export default shortedNumber