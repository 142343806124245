export class TON {
    static instance;
    ton;
    client;
    receiver;
    receiver_swap;
    sender;



    constructor(tonConnectUI, userFriendlyAddress) {
        if (TON.instance) {
            return TON.instance
        }
       

        this.ton = tonConnectUI

        this.sender = userFriendlyAddress

        this.receiver = process.env.REACT_APP_WALLET_ADDRESS
        this.receiver_swap = process.env.REACT_APP_SWAP_TON_ADDRESS


        TON.instance = this

    }

   async sendTransaction(amount) {
        const transaction = this.getMessage(amount)

        try {
        
            const result = await this.ton.sendTransaction(transaction)

            
            return result.boc
            

        } catch (error) {
            console.log(error)
            return null
        }

   }

   async sendTransactionWithPayload(amount, payload) {
        const transaction = this.getMessageWithPayload(amount, payload)

        console.log(transaction)

        try {
        
            const result = await this.ton.sendTransaction(transaction)

            
            return result.boc
            

        } catch (error) {
            console.log(error)
            return null
        }
   }

   getMessage(amount) {
        const message = {
            validUntil: Math.floor(Date.now() / 1000) + 360, // 6 mins from now
            messages: [
                {
                    address: this.receiver,
                    amount: Math.floor(amount * 1e9),
                
                }
            ]
        }

        return message
   }

   getMessageWithPayload(amount, payload) {

        const message = {
            validUntil: Math.floor(Date.now() / 1000) + 360, // 6 mins from now
            messages: [
                {
                    address: this.receiver_swap,
                    amount: Math.floor(amount * 1e9),
                    payload: payload
                }
            ]
        }

        return message
   }

   
   
}
