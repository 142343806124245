import "./style.css";
import AppWidthWithContainer from "components/AppWidthContainer/AppWidthWithContainer";
import { HeaderCells } from "components/HeaderCells/HeaderCells";
import Title from "components/Title/Title";
import { useGlobalStore } from "stores/global";
import { HistoryTable } from "components/HistoryTable/HistoryTable";
import CommonMode from "./assets/common-mode.png";
import MatchMakingMode from "./assets/matchmaking-mode.png";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Loader from "components/icons/Loader";
import { getPageData } from "helpers/getPageData";
import infoIcon from "./assets/info.svg";
import { Tooltip } from "react-tooltip";

export const BarKingChoice = () => {

    const [isActiveCommon, setIsActiveCommon] = useState(false);
    const [isActiveMatchMaking, setIsActiveMatchMaking] = useState(false);

    const handleMouseDownCommon = () => setIsActiveCommon(true);
    const handleMouseUpCommon = () => setIsActiveCommon(false);

    const handleMouseDownMatchMaking = () => setIsActiveMatchMaking(true);
    const handleMouseUpMatchMaking = () => setIsActiveMatchMaking(false);

    const tg_info = useGlobalStore(state => state.tg_info );
    const avatar = useGlobalStore(state => state.user_info.avatar);
    const token_balance = useGlobalStore(state => state.user_info.token_balance );
    const api = useGlobalStore(state => state.api);

    const language = useGlobalStore(state => state.user_info.language);
    
    const last_games_history = useGlobalStore(state => state.last_games_history);
    const { setLastGamesHistory, setNotification } = useGlobalStore();

    const navigate = useNavigate();

    const pageData = getPageData('bar_king_choice');

    const openCommonMode = () => {
        navigate("/game/king-of-the-bar/0");
    }

    const openMatchMakingMode = () => {
        navigate("/game/king-of-the-bar/1");
    }

    useEffect(() => {
      const fn = async () => {
        if(last_games_history) {
            return 
        }

        const gamesHistory = await api.getLastBarKingGames();
        
       

        if(!gamesHistory) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: 'Failed to fetch last games history. Please try again later'
            }

            setNotification(notification);
            return
        }

        setLastGamesHistory(gamesHistory);
        
      }
      

      fn()

    },[])




    return <AppWidthWithContainer className="bar-king-choice">
            <Tooltip anchorSelect = ".info-icon-common" place= "top" className = "info-tooltip">
                {pageData.common_mode.tooltip[language]}
            </Tooltip>
            <Tooltip anchorSelect = ".info-icon-matchmaking" place= "top" className = "info-tooltip">
                {pageData.matchmaking_mode.tooltip[language]}
            </Tooltip>
            <HeaderCells image = {avatar} text = {tg_info.tg_name} tokenBalance = {token_balance}/>
            <div className = "bar-king-choice__content">
                <Title text={pageData.title[language]} size = "medium"/>
                <div className = "bar-king-choice__cards">
                    <div className="bar-king-choice__card" style = {{transform: isActiveCommon ? 'scale(0.95)' : 'scale(1)'}}>
                        <div className = "bar-king-choice__card-header">
                            <img src = {infoIcon} alt = "" className = "info-icon-common"/>
                            <span className = "bar-king-choice__card-title">{pageData.common_mode.title[language]}</span>
                        </div>
                        
                        <div className = "bar-king-choice__card-body" 
                        onClick = {openCommonMode} 
                        onMouseDown={handleMouseDownCommon} 
                        onMouseUp={handleMouseUpCommon}
                        onMouseLeave={handleMouseUpCommon}>
                            <img src = {CommonMode} alt = ""/>
                            <span className = "bar-king-choice__card-description">{pageData.common_mode.text[language]}</span>
                        </div>
                    </div>
                    <div className="bar-king-choice__card" style = {{transform: isActiveMatchMaking ? 'scale(0.95)' : 'scale(1)'}}>
                        <div className = "bar-king-choice__card-header">
                            <img src = {infoIcon} alt = "" className = "info-icon-matchmaking"/>
                            <span className = "bar-king-choice__card-title">{pageData.matchmaking_mode.title[language]}</span>
                        </div>
                        <div className = "bar-king-choice__card-body" 
                        onClick = {openMatchMakingMode}
                        onMouseDown={handleMouseDownMatchMaking}
                        onMouseUp={handleMouseUpMatchMaking}
                        onMouseLeave={handleMouseUpMatchMaking}>
                            <img src = {MatchMakingMode} alt = ""/>
                            <span className = "bar-king-choice__card-description">{pageData.matchmaking_mode.text[language]}</span>
                        </div>
                    </div>
                </div>
                <span className = "bar-king-choice__history">{pageData.history_table.title[language]}</span>

                {!last_games_history ? 
                <div className = "last_games__history-loader">
                <Loader fill = "#582B02" width = {40} height = {40}/>
                </div>
                :<HistoryTable language = {language} pageData = {pageData.history_table} data = {last_games_history}/>
                }
            </div>
    </AppWidthWithContainer>
}