import React from "react";
import "./style.css";


export const BigButton = ({ img, text, type, connected, handleClick,language }) => {

  return (
    <button 
    id = {type == "connect" ? "connect-ton-btn" : ''}
      className={`big-button ${connected ? "connected" : ""}`}
      onClick={handleClick}
    >
      <img className="big-button__img" src={img} alt="" />
      <span className="big-button__text" style = {language !== "en" ? {fontWeight:'800'}:{}}>{text}</span>
    </button>
  );
};
