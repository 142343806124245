import "./style.css"
import TonIcon from "./assets/ton-icon.png"
import { unixToDateString } from "helpers/unixToDateString"
import formatAddress from "helpers/formatAddress"
import successIcon from "./assets/success-icon.png"
import waitingIcon from "./assets/waiting-icon.png"
import cancelIcon from "./assets/cancel-icon.png"

export const TransactionItem = ({ key,amount,created_at, hash,status,type }) => {
    
//type 0,1
//0 - buy
//1 - sell

    return <div className = "transaction__history-item" key = {key}>
    <img src = {TonIcon} alt = ""/>
    <span className = "transaction__history-item-title">{type === 0 ? amount + " TON": 0 - amount + " TON"}</span>
    <div className = "transaction__history-item-text">
        <span>{unixToDateString(created_at)}</span>
        <span className = "transaction__history-item-hash">
            {formatAddress(hash, 6)}
        </span>
    </div>
    <img src = {getIcon(status)} alt = ""/>
</div>
}

const getIcon = (status) =>{
    const statuses = {
        0: waitingIcon,
        1: successIcon,
        2: cancelIcon
    }

    return statuses[status]
}