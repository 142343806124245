import "./style.css"
import AppWidthWithContainer from "components/AppWidthContainer/AppWidthWithContainer"
import { HeaderCells } from "components/HeaderCells/HeaderCells"
import { useGlobalStore } from "stores/global"
import Title from "components/Title/Title"
import { SocialCard } from "components/SocialCard/SocialCard"
import { BountyCard } from "components/BountyCard/BountyCard"
import { KlondikeButton } from "components/KlondikeButton/KlondikeButton"
import ModalWindow from "components/ModalWindow/ModalWindow"
import { useState, useEffect } from "react"
import Link from "./assets/link.png"
import CopyIcon from "./assets/copy-icon.png"
import ArrowRight from "./assets/arrow-right.png"
import Loader from "components/icons/Loader"

import TreasureIsland from "./assets/treasure-island-3.png"
import { getPageData } from "helpers/getPageData"
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig"

import { BountyItem } from "components/BountyItem/BountyItem"

import { isValidURL } from "helpers/isValidUrl"

export const Bounty = () => {

    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isOpenRulesForm, setIsOpenRulesForm] = useState(false);

    const [sending, setSending] = useState(false);

    //send request form start
    const [linkText, setLinkText] = useState("");
    const [comment, setComment] = useState("");
    //send request form end

    const tg_info = useGlobalStore(state => state.tg_info );
    const avatar = useGlobalStore(state => state.user_info.avatar );
    const language = useGlobalStore(state => state.user_info.language );
    const api = useGlobalStore(state => state.api );
    const token_balance = useGlobalStore(state => state.user_info.token_balance );

    const bounty_history = useGlobalStore(state => state.bounty_history );

    const {setNotification,setBountyHistory, addSubmittedBounty} = useGlobalStore();

    const pageData = getPageData('bounty_page');
    const infoWindows = getInfoWindowsConfig("bounty_request")

    const defaultMsg = getInfoWindowsConfig("default")

    const bountyMsg = getInfoWindowsConfig("bounty")

    const instructions = [...Object.values(pageData.rules_window.instructions)]
    const restrictions = [ ...Object.values(pageData.rules_window.restrictions)]

    useEffect(() => {
        const fn = async () => {
            const bountyHistory = await api.getUserBounties();

            if(!bountyHistory) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: bountyMsg.load_fail[language]
                }

                setNotification(notification)
                return
            }

            setBountyHistory(bountyHistory)

        }

        if(!bounty_history) {
            fn()
        }
    },[])
 
    const handleOpenForm = () => {
        setIsOpenForm(true);
      };
    
    const handleCloseForm = () => {
        setIsOpenForm(false);
        
    };

    const handleOpenRulesForm = () => {
        setIsOpenRulesForm(true);
      };
    
    const handleCloseRulesForm = () => {
        setIsOpenRulesForm(false);
        
    };

    const handlePasteClick = async () => {
        const pastedText = await navigator.clipboard.readText(); 
        setLinkText(pastedText);
    };

    const changeInputHandler = (e) => {
        e.preventDefault()
        setLinkText(e.target.value)
    }

    const changeCommentHandler = (e) => {
        e.preventDefault()
        setComment(e.target.value)
    }

    const sendRequest = async () => {
        if(!linkText) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: infoWindows.empty_link[language]
            }

            setNotification(notification)
            return 
        }

        if(!isValidURL(linkText)) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: infoWindows.invalid_link[language]
            }

            setNotification(notification)
            return
        }
        
        setSending(true)
        const submitRequest = await api.submitBounty(linkText, comment)
        
        setSending(false)

        if(!submitRequest) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: defaultMsg.error[language]
            }

            setNotification(notification)
            return
        }

        const notification = {
            show: true,
            points: '',
            username: '',
            message: bountyMsg.success[language]
        }

        setNotification(notification)
        addSubmittedBounty(submitRequest)

        clearForm()
    }

    const clearForm = () => {
        setLinkText("")
        setComment("")
    }

    return <AppWidthWithContainer>
        <HeaderCells image = {avatar} text = {tg_info.tg_name} tokenBalance = {token_balance}/>
        <div className = "bounty-page">
            <Title size = "medium" text = {pageData.title[language]} />
            <img src = {TreasureIsland} alt = "treasure-island"/>
            <span>{pageData.social.social_title[language]}</span>
            <div className = "bounty-page__cards">
                <SocialCard type = "twitter" text = {pageData.social.twitter[language]}/>
                <SocialCard type = "telegram" text = {pageData.social.telegram[language]}/>
                <SocialCard type = "youtube" text = {pageData.social.youtube[language]}/>
                <SocialCard type = "tiktok" text = {pageData.social.tiktok[language]}/>
            </div>
            <span>{pageData.rewards.rewards_title[language]}</span>
            <div className = "bounty-page__cards">
                <BountyCard type = "points" text = {pageData.rewards.points[language]}/>
                <BountyCard type = "KLD" text = {pageData.rewards.tokens[language]}/>
                <BountyCard type = "TON" text = {pageData.rewards.ton[language]}/>
            </div>
            <div className = "bounty-page__btns">
                <KlondikeButton style = "wooden" text = {pageData.btns.rules[language]} clickHandler = {handleOpenRulesForm}/>
                <KlondikeButton style = "gold" text = {pageData.btns.order[language]} clickHandler = {handleOpenForm}/>
            </div>
            <Title size = "small" text = {pageData.history[language]} />
            <div className = "bounty-page__history">
                {
                    !bounty_history ? <Loader fill="#582B02" width={30} height={30} /> : 
                    bounty_history.sort((a, b) => b.created_at - a.created_at).map((item, index) => <BountyItem key = {index} {...item}/>)
                }
            </div>
            <ModalWindow isOpen={isOpenForm} onClose={handleCloseForm}>
                <div className = "bounty-page__form">
                    <div className = "bounty-page__form__link">
                        <img id = "link-img" src = {Link} alt = ""/>
                        <input id = "link-input" placeholder = {pageData.order_window.input[language]} type = "text" value = {linkText} onChange = {changeInputHandler}/>
                        <img id = "copy-img" src = {CopyIcon} alt = "" onClick = {handlePasteClick}/>
                    </div>
                    <textarea className = "bounty-page__form__textarea" placeholder = {pageData.order_window.comment[language]} value = {comment} onChange = {changeCommentHandler}/>
                    <KlondikeButton loading = {sending} style = "sharp" text = {pageData.order_window.send_btn[language]} img = {ArrowRight} clickHandler = {sendRequest}/>
                </div>
            </ModalWindow>

            <ModalWindow isOpen = {isOpenRulesForm} onClose = {handleCloseRulesForm} className = "scrollable">
                <div className = "bounty-page__form">
                    <span className = "bounty-page__form__title"> {pageData.rules_window.title_top[language]}</span>
                    
                    <div className = "bounty-page__form__text-list">
                        {
                            instructions.map((item, index) => <span className = "bounty-page__form__text"> {`${index + 1}. ${item[language]}`}</span>)
                        }
                    </div>

                    <span className = "bounty-page__form__title"> {pageData.rules_window.title_bottom[language]}</span>

                    <div className = "bounty-page__form__text-list">
                        {
                            restrictions.map((item, index) => <span className = "bounty-page__form__text"> {`${index + 1}. ${item[language]}`}</span>)
                        }
                    </div>
                    
                </div>
            </ModalWindow>
        </div>
    </AppWidthWithContainer>
}