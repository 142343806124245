
import axios from "axios";

export class ChainStack {
    static instance;
    baseApiUrl;

    constructor() {
        if(ChainStack.instance) {
            return ChainStack.instance
        }

        this.baseApiUrl = process.env.REACT_APP_CHAINSTACK_API

        ChainStack.instance = this
    }

    getApiUrlV2(method = null) {
        return `${this.baseApiUrl}/api/v2${method ? `/${method}` : ''}`
    }

    getApiUrlV3(method = null) {
        return `${this.baseApiUrl}/api/v3${method ? `/${method}` : ''}`
    }

    generateBody(method,params) {
        return {
            "jsonrpc": "2.0",
            "method": method,
            "id": 1,
            "params": params
        }
    }

    fromNanotons(nanotons) {
        return Number(nanotons) / 1e9
    }

    async getWalletTonBalance(address) {
        const apiUrl = this.getApiUrlV2('getAddressBalance')

        const params = {
            address
        }

        try {
            const response = await axios.get(apiUrl, {params})

            return this.fromNanotons(response.data.result)

        } catch(err) {
            console.log(err)
            return null
        }
        
        
    }
}