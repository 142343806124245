import "./style.css"
import Points from "./assets/points.png"
import KlondikeCoin from "./assets/klondike-coin-logo.png"
import shortedNumber from "helpers/shortedNumber"

import points1 from "./assets/points-1.png"
import points2 from "./assets/points-2.png"
import points3 from "./assets/points-3.png"
import points4 from "./assets/points-4.png"
import points5 from "./assets/points-5.png"

import tokens1 from "./assets/tokens-1.png"
import tokens2 from "./assets/tokens-2.png"
import tokens3 from "./assets/tokens-3.png"
import tokens4 from "./assets/tokens-4.png"
import tokens5 from "./assets/tokens-5.png"

import EmptyChest from "./assets/empty-chest.png"
import Loader from "components/icons/Loader"

import anonim from "./assets/anonimous-miner.png"

import { useTimer } from "react-timer-hook"
import { useEffect } from "react"


export const BarKingTable = ({gamemode, currentBank, lastBet, loading, winnerFound, timer,pageData,language,bankRef}) => {


     //timer start
    const {
            totalSeconds,
            seconds,
            minutes,
            hours,
            days,
            isRunning,
            start,
            pause,
            resume,
            restart,
        } = useTimer({ autoStart: false , onExpire: () => console.warn('onExpire called') });
        //timer end

    
    useEffect(() => {
       restart(timer,true) 
    },[timer])

    if(loading) {
        return <div className = "bar-king-table">
             <Loader fill="#E7A510" width={30} height={30} />
        </div>
    }

    if(winnerFound.winner) {
        return <div className = "bar-king-table">
            <span className = "gradient-text">{pageData.winner[language]}</span>
            <img className = "bar-king-table_winner-img" onError = {(e) => (e.target.src = anonim)} src = {winnerFound.winner.avatar} alt = ""/>
            <span className = "gradient-text">{winnerFound.winner.username}</span>
            <span className = "bar-king-table__bank-title">{pageData.prize[language]}</span>
            <div className = "bar-king-table__bank-number">
                <img src = {gamemode === "0" ? Points : KlondikeCoin} alt = ""/>
                <span>{shortedNumber(winnerFound.prize)}</span>
            </div>
        </div>
    }

    return <div className = "bar-king-table">
            <span className = "bar-king-table__bank-title">{pageData.bank[language]}</span>
            <div className = "bar-king-table__bank-number">
                <img style = {{width:"45px", height: '45px'}}src = {gamemode === "0" ? Points : KlondikeCoin} alt = ""/>
                <span style = {{fontSize:"38px"}}>{shortedNumber(currentBank)}</span>
            </div>
            {
             currentBank > 0 ?
                <>
                <img ref = {bankRef} style = {{width: getWidthByStage(determineStage(currentBank))}} className = " bar-king-table__img" src = {getImgByBank(currentBank,gamemode)} alt = ""/>
                <span className = "bar-king-table__time-title">{pageData.time_left[language]}</span>
                <div className = "bar-king-table__time-number">
                    <span>{hours < 10 ? "0" + hours : hours}</span>
                    <span>:</span>
                    <span>{minutes < 10 ? "0" + minutes : minutes}</span>
                    <span>:</span>
                    <span>{seconds < 10 ? "0" + seconds : seconds}</span>
                </div>
                <span className = "bar-king-table__bet-title">{pageData.last_bet[language]}</span>
                <div className = "bar-king-table__bank-number">
                    <img src = {gamemode === "0" ? Points : KlondikeCoin} alt = ""/>
                    <span>{shortedNumber(lastBet)}</span>
                </div>
                </>
                :
                <div className = "bar-king-table__empty">
                    <img src = {EmptyChest} alt = ""/>
                    <div className = "bar-king-table__empty-text">
                        <span className = "gradient-text">{pageData.make[language]}</span>
                        <span className = "gradient-text">{pageData.first_bet[language]}</span>
                    </div>
                </div>
                
            }
            
    </div>
}


const getImgByBank = (bank,gamemode) => {
    const images = {
        "0": [
            points1, points2, points3, points4, points5
        ],
        "1": [
            tokens1, tokens2, tokens3, tokens4, tokens5
        ]
    }

    const stage = determineStage(bank);

    return images[gamemode][stage];
}


function determineStage(number) {
    // Convert number to tons
    const tons = number / 1000;

    // Define stage limits in tons
    const limits = [0, 1, 10, 50, 100]; // Stages: 0-4 based on ton ranges

    // Determine the stage
    for (let i = 0; i < limits.length; i++) {
        if (tons < limits[i]) {
            return i - 1; // Return the previous stage
        }
    }

    // If number exceeds all limits, return the highest stage
    return limits.length - 1;
}

function getWidthByStage(stage) {
    const width = {
        0: "40.7px",
        1: "51.7px",
        2: "91.3px",
        3: "114.4px",
        4: "138.6px"
    };

    return width[stage];
}