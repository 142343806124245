import React from "react";
import "./style.css";

function Title({ text,size }) {
  
  const getSize = ()=>{
    if(size === "big") {
      return "56px";
    } else if(size == "medium") {
      return "36px";
    } else if(size == "small") {
      return "24px";
    } else {
      return "56px";
    }
  }

  return (
    <h2 className="title" data-title={text} style = {{fontSize: getSize()}}>
      {text}
    </h2>
  );


  
}

export default Title;
