import React from "react";

import "./style.css";
import anonim from "./assets/img/anonimous-miner.png"

function ShortCell({ image, text, avatar, onClick }) {
  return (
    <button onClick={onClick} className="cell">
      {image ? <img src={image} onError = {(e) => (e.target.src = anonim)} class = {avatar ? "avatar-img" : "coin-img"} alt="" /> : null}
      <span className="cell__text">{text}</span>
    </button>
  );
}

export default ShortCell;
