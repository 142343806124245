import gameRulesData from "config/gameRulesData.json";
import BanditsRuleImg1 from "components/GameRulesSlider/assets/banditsInvasion/step1.jpg"
import BanditsRuleImg2 from "components/GameRulesSlider/assets/banditsInvasion/step2.png"
import BanditsRuleImg3 from "components/GameRulesSlider/assets/banditsInvasion/step3.jpg"
import BanditsRuleImg4 from "components/GameRulesSlider/assets/banditsInvasion/step4.png"

import kingBarImg1 from "components/GameRulesSlider/assets/king-of-the-bar/step1.png"
import kingBarImg2 from "components/GameRulesSlider/assets/king-of-the-bar/step2.png"
import kingBarImg3 from "components/GameRulesSlider/assets/king-of-the-bar/step3.png"

export const getGameRulesData = (game) => {
    const images = {
        "Bandits invasion": [
            BanditsRuleImg1,
            BanditsRuleImg2,
            BanditsRuleImg3,
            BanditsRuleImg4
        ],
        "King of the bar": [
            kingBarImg1,
            kingBarImg2,
            kingBarImg3,
        ]
    }
    return gameRulesData[game].map((step, index)=>{
        return {
            ...step,
            img: images[game][index]
        }
    })
}

//TO-DO: add images