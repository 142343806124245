import "./style.css"
import Cross from "./assets/button-Cross.svg"
import { GameRulesSlider } from "components/GameRulesSlider/GameRulesSlider";
import Loader from "components/icons/Loader";
import { useState, useEffect } from "react";
import { useGlobalStore } from "stores/global";
import { useGameStore } from "stores/game";

export const GamePopup = ({isOpen, onClose, title, language,rulesData,gameHandler, attempts, loading, loadingHandler}) => {

  const [attemptsNum, setAttemptsNum] = useState(attempts);

  const {setNotification} = useGlobalStore();
  const {setBanditsGameAttempts} = useGameStore();

  const api = useGlobalStore(state => state.api);


  useEffect(() => {
    const fn = async () => {
        if(title == "Bandits invasion") {
            loadingHandler(true)
            const banditsGameAttempts = await api.getAttemptsLeft(1)
            loadingHandler(false)

            if(!banditsGameAttempts) {
              const notification = {
                show: true,
                points: '',
                username: '',
                message: 'Failed to fetch game info. Please try again later'
              }
    
              setNotification(notification)
              return
            }

            setAttemptsNum(banditsGameAttempts.attempts)
            setBanditsGameAttempts(banditsGameAttempts.attempts)
            
        }
    } 

    if(attempts == null) {
        fn()
    }
  },[isOpen])

  useEffect(() => {
    setAttemptsNum(attempts)
  },[attempts])

    return (
        <div className={`game-popup ${isOpen ? "game-popup-open" : ""}`}>
            
          <div className="game-popup-content">
          <button className="game-popup-close" onClick={onClose}>
              <img className="game-popup__btn" src={Cross} alt="" />
            </button>
            <span className = "game-popup__title">
              {title}
            </span>
            <span className="game-popup__rules-text">
                    {language === "ru" ? "Правила Игры" : "Game Rules"}
                </span>
            <div className="game-popup__slider">
            <GameRulesSlider data = {rulesData} language = {language}/>
            </div>
            <button className = "game-popup__start-game-button" onClick = {gameHandler}>
                <span className="history-item__desc">{loading ? <Loader fill="#582B02" width={18} height={18} />: language === "ru" ? `Играть (осталось ${typeof attempts === "number" ? "x" + attemptsNum : attemptsNum})` : `Play (left ${typeof attempts === "number" ? "x" + attemptsNum: attemptsNum})`}</span>
             </button>
          </div>
          
        </div>
      );
        
}