import React from "react";
import "./style.css";
import { LevelItem } from "../LevelItem/LevelItem";
import Loader from "components/icons/Loader";

export const Levels = ({friends,language,pageData}) => {
  return (
    <div className="levels">
      <LevelItem numbers={getFriendState(friends,0)} desc={pageData.partners.level_1.title[language]} text={pageData.partners.level_1.text[language]} />
      <LevelItem numbers={getFriendState(friends,1)} desc={ pageData.partners.level_2.title[language]} text={ pageData.partners.level_2.text[language]} />
      <LevelItem numbers={getFriendState(friends,2)} desc={ pageData.partners.level_3.title[language]} text={ pageData.partners.level_3.text[language]} />
    </div>
  );
};

function getFriendState(arr,level) {
  if(!arr) {
    return <Loader fill = "#FFFFFF" height = {20} width = {20}/>
  } 

  return arr[level]
}
