import "./style.css";
import successIcon from "./assets/success-icon.png"
import waitingIcon from "./assets/waiting-icon.png"
import cancelIcon from "./assets/cancel-icon.png"

import Points from "./assets/points-img.png"
import KLD from "./assets/kld-img.png"
import TON from "./assets/toncoin-ton-logo.svg"

import formatAddress from "helpers/formatAddress";
import { unixToDateString } from "helpers/unixToDateString";
import shortedNumber from "helpers/shortedNumber";
import { useGlobalStore } from "stores/global";
import { getPageData } from "helpers/getPageData";

export const BountyItem = ({ key, uuid, url,description,status,reward_amount,currency_type,created_at }) => {

    const language = useGlobalStore(state => state.user_info.language);
    const pageData = getPageData("bounty_page")

    return <div className = {`bounty-item ${getClassName(status)}`} key = {key}>
            <div className = "bounty-item__content">
                <span className = "bounty-item__title">{pageData.bounty_history.id[language]}</span>
                <span className = "bounty-item__text">{formatAddress(uuid, 4)}</span>
            </div>
            <div className = "bounty-item__content">
                <span className = "bounty-item__title">{pageData.bounty_history.date[language]}</span>
                <span className = "bounty-item__text">{unixToDateString(created_at,true)}</span>
            </div>
            {
                status!== 1 && <div className = "bounty-item__content"></div>
            }
           {
            status == 1 &&  <div className = "bounty-item__content">
                        <span className = "bounty-item__title">{pageData.bounty_history.reward[language]}</span>
                        <div className = "bounty-item__reward">
                            <span className = "bounty-item__text">{shortedNumber(reward_amount)}</span>
                            <img src = {getCurrency(currency_type) } alt = ""/>
                        </div>
                    </div>
           }
            <img className = "bounty-item__status-img" src = {getIcon(status)} alt = ""/>
    </div>
}

const getIcon = (status) =>{
    const statuses = {
        0: waitingIcon,
        1: successIcon,
        2: cancelIcon
    }

    return statuses[status]
}

const getClassName = (status) => {
    const statuses = {
        0: "waiting",
        1: "accepted",
        2: "canceled"
    }

    return statuses[status]
}

const getCurrency = (currency_type) => {
    const currencies = {
        0: Points,
        1: KLD,
        2: TON
    }

    return currencies[currency_type]
}